import { employerPrefix, getEmployerType } from "utilities/url"

export function fullInternalLinkPath(path?: string): string {
  if (!path) {
    return ""
  }
  const authRequestRegex = /insurance\/authorizations/
  let fullPath

  // to meet CCX pilot deadline:
  // Paypals has only built out payor and supplier routes for the auth request.
  // Remove this regex check as we build out ClinicalFacility, ClinicalOrganization,
  // and Doctor facing routes
  if (authRequestRegex.test(path)) {
    const isUnsupportedEmployer = [
      "ClinicalFacility",
      "ClinicalOrganization",
      "Doctor",
    ].includes(getEmployerType())
    fullPath = isUnsupportedEmployer ? "" : `${employerPrefix()}/${path}`
  } else {
    fullPath = `${employerPrefix()}/${path}`
  }
  return fullPath
}
