import React from "react"
import { Event, Notice as NoticeType, Nullable } from "sharedTypes"
import ExternalLinks from "./ExternalLinks"
import Notice from "./utilities/Notice"
import { EventRawContent, PlainTextEventContent } from "./EventRawContent"
import ServiceValidationMessage from "../../applications/Workflow/containers/Sidebar/containers/PatientPortal/components/ServiceValidationMessage"
import { fullInternalLinkPath } from "./internalLinkPath"
import { CanopyLink } from "@parachutehealth/canopy-link"

type Props = {
  event: Event
  notice?: Nullable<NoticeType>
  maxLength?: number
}

const innerContent = ({
  event,
  maxLength,
}: {
  event: Event
  maxLength?: number
}) => {
  if (event.data?.serviceValidation) {
    return (
      <ServiceValidationMessage
        serviceValidation={event.data.serviceValidation}
        supplierName={event.userEmployerName}
      />
    )
  } else if (event.rawContent) {
    return <EventRawContent event={event} maxLength={maxLength} />
  } else {
    return <PlainTextEventContent text={event.content} />
  }
}

const Content: React.FC<Props> = ({ event, notice, maxLength, children }) => {
  const {
    content,
    externalLinks,
    internalLink,
    outerContent,
    rawContent,
  } = event

  const internalLinkPath = fullInternalLinkPath(internalLink?.path)
  const internalLinkText = internalLink?.text

  return (
    <>
      {outerContent && <div className="outer-message-body">{outerContent}</div>}
      {(content || rawContent) && (
        <div className="message-body">
          {innerContent({ event, maxLength })}{" "}
          <ExternalLinks links={externalLinks} />
          {internalLinkPath && internalLinkText && (
            <CanopyLink href={internalLinkPath} target="_blank">
              {`- ${internalLinkText}`}
            </CanopyLink>
          )}
          {children}
        </div>
      )}
      {notice && <Notice notice={notice} />}
    </>
  )
}

export default Content
